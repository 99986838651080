













































































import Vue from 'vue';

import FreeParkingSpaces from '../components/FreeParkingSpaces.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    FreeParkingSpaces,
  },
  props: {
    logoImg: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    if (this.$authenticated) {
      // Call api to use redirection matrix (ignore fetched data)
      this.$api.getStatus().then();
    }
  },
});
